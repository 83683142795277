<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{$t('teaGardenBtriService.clone_report')}} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
          <b-row>
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Release Year" vid="release_year" rules="">
              <b-form-group
                label-for="release_year"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('teaGardenConfig.release_year')}}
              </template>
               <b-form-input
                id="release_year"
                v-model="search.release_year"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Type" vid="type" rules="">
              <b-form-group
                label-for="type"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.type')}}
              </template>
              <b-form-select
                plain
                v-model="search.type_id"
                :options="typeList"
                id="type"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Clone Class" vid="clone_class" rules="">
              <b-form-group
                label-for="clone_class"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('teaGardenConfig.clone_class')}}
              </template>
              <b-form-select
                plain
                v-model="search.clone_class_id"
                :options="cloneClassList"
                id="clone_class"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Quality" vid="quality_id" rules="">
              <b-form-group
                label-for="quality_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('teaGardenConfig.quality')}}
              </template>
              <b-form-select
                plain
                v-model="search.quality_id"
                :options="qualityList"
                id="quality_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
              <b-col sm="9">
              </b-col>
              <b-col sm="3" style="text-align: right;">
                <b-button size="sm" type="submit" variant="primary" class="mt-20">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.clone_report') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
        <div>
          <b-row class="text-right mt-2">
            <b-col>
               <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
               </b-button>
            </b-col>
          </b-row>
          <div class="table-wrapper table-responsive" >
            <!-- <ReportHeading /> -->
            <table class="table table-striped table-hover table-bordered">
            <tr>
              <th colspan="15" style="text-align: center;">{{ $t('teaGardenBtriService.clone_report_heading') }}</th>
            </tr>
            <tr>
              <th rowspan="2">{{$t('globalTrans.sl_no')}}</th>
              <th rowspan="2">{{ $t('teaGardenConfig.clone_name') }}</th>
              <th rowspan="2">{{$t('teaGardenConfig.release_year')}}</th>
              <th colspan="3" class="text-center">{{$t('teaGardenBtriService.production')}}</th>
              <th rowspan="2">{{$t('teaGardenConfig.quality')}}</th>
              <th rowspan="2">{{$t('teaGardenConfig.clone_class')}}</th>
            </tr>
            <tr>
              <th>{{ $t('teaGardenConfig.immature_second_to_five') }}</th>
              <th>{{ $t('teaGardenConfig.mature_six_to_thirteen') }}</th>
              <th>{{ $t('teaGardenConfig.max_avg_production') }}</th>
            </tr>
              <template v-if="itemList && itemList.length > 0">
              <tr v-for="(item,index) in itemList" :key="index">
                <td>{{ $n(index + 1) }}</td>
                <td>{{ currentLocale === 'bn' ? item.clone_name_bn : item.clone_name_en }}</td>
                <td>{{ $n(item.release_year, {useGrouping:false}) }}</td>
                <td class="text-right">{{ $n(item.immature_second_to_five) }}</td>
                <td class="text-right">{{ $n(item.mature_six_to_thirteen) }}</td>
                <td class="text-right">{{ $n(item.max_avg_production) }}</td>
                <td>{{ getQualityName(item.quality_id) }}</td>
                <td>{{ getCloneClassName(item.clone_class_id) }}</td>
              </tr>
              </template>
              <template v-else>
                  <tr>
                      <td colspan="13" align="center">{{ $t('globalTrans.noDataFound') }}</td>
                  </tr>
              </template>
          </table>
          </div>
        </div>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
// import ReportHeading from '../../../../pages/report-heading/ReportHeading.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { getCloneReport } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    // ReportHeading
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
        release_year: '',
        type_id: 0,
        quality_id: 0,
        clone_class_id: 0
      },
      viewitemId: 0,
      itemList: []
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
     cloneClassList () {
      return this.$store.state.TeaGardenService.commonObj.masterCloneClassList.filter(item => item.status === 1)
    },
    yearList () {
      return this.$store.state.commonObj.yearList.map((item, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.$n(item, { useGrouping: false }) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    qualityList () {
      let qualityList = [
        {
          value: 1,
          text_en: 'Good',
          text_bn: 'উত্তম '
        },
        {
          value: 2,
          text_en: 'Good and Fragrant',
          text_bn: 'উত্তম ও সুবাসিত '
        },
        {
          value: 3,
          text_en: 'Excellent',
          text_bn: 'উৎকৃষ্ট'
        }
      ]
      qualityList = qualityList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn, text_en: item.text_en, text_bn: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en, text_en: item.text_en, text_bn: item.text_bn }
        }
      })
      return qualityList
    },
    typeList () {
      let typeList = [
        {
          value: 1,
          text_en: 'Seed',
          text_bn: 'বীজ '
        },
        {
          value: 2,
          text_en: 'Clone',
          text_bn: 'ক্লোন'
        }
      ]
      typeList = typeList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn, text_en: item.text_en, text_bn: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en, text_en: item.text_en, text_bn: item.text_bn }
        }
      })
      return typeList
    }
  },
  watch: {
    'search.section_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.chapterList = this.getChapterList(newVal)
      } else {
        this.chapterList = []
      }
    }
  },
  methods: {
     getCloneClassName (cloneClassId) {
        const cateObj = this.$store.state.TeaGardenService.commonObj.masterCloneClassList.find(item => item.value === cloneClassId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
     getQualityName (unitId) {
       const qualityList = [
        {
          value: 1,
          text_en: 'Good',
          text_bn: 'উত্তম '
        },
        {
          value: 2,
          text_en: 'Good and Fragrant',
          text_bn: 'উত্তম ও সুবাসিত '
        },
        {
          value: 3,
          text_en: 'Excellent',
          text_bn: 'উৎকৃষ্ট'
        }
      ]
      const cateObj = qualityList.find(item => item.value === unitId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.itemList = []
      this.searchType = 0
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, getCloneReport, params)
      if (result.success) {
        this.itemList = result.data
      } else {
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async pdfExport () {
        const params = Object.assign({}, this.search, { request_type: 'pdf', local: this.$i18n.locale, org_id: 5 })
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 20)
            if (service !== undefined) {
              if (service.office_type_id) {
                params.office_type_id = service.office_type_id
              }
              if (service.office_id) {
                params.office_id = service.office_id
              }
            }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, getCloneReport, params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
